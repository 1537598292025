import React, { useContext } from "react";
import { CostomContext } from "../../../_context.jsx";
import "./restwell.scss";
import { Link } from "react-router-dom";
import Pavilion from "./pavilion/pavilion.jsx";

export default function RestWell() {
  const { PATCHNAME, form, setForm, UpdateData } = useContext(CostomContext);

  return (
    <form onSubmit={(e) => UpdateData(e)}>
      <div
        className={
          form.end5
            ? "div--form end--active"
            : form.form5
            ? "div--form form--active"
            : "div--form"
        }
      >
        <span className="greeting--form">
          Вы едете на настоящую ферму, где производят настоящие продукты, в том
          числе мясо и птицу. Если Вы планируете устроить шашлычную вечеринку
          или просто пожарить мясо в семейном кругу, напишите нам за сутки Ваши
          пожелания, и мы все подготовим, замаринуем и подадим в лучшем виде.
        </span>
        <Pavilion />
        <div className="form-btn">
          <Link to={"/" + PATCHNAME + "?next=3"}>
            <button
              className="btn btn-secondary"
              type="button"
              onClick={() => setForm({ ...form, end5: true })}
            >
              <span>Назад</span>
            </button>
          </Link>
          {/* <Link to={"/" + PATCHNAME + "?next=4"}> */}
            <button className="btn btn-primary pestwell-btn" type="submit">
              <span>Отправить</span>
            </button>
          {/* </Link> */}
        </div>
      </div>
    </form>
  );
}
