import React, { useContext } from "react";
import "./_alert.scss";
import { CostomContext } from "../../_context.jsx";

export default function Alert() {
  const { tops, AlertText, show_on } = useContext(CostomContext);
  return (
    <div className="alert">
      {show_on && (
        <div style={{ top: `${tops}` }} className="alert-show">
          {AlertText
            ? AlertText
            : `Спасибо за ваш заказ! \n Данные уже отправлены!`}
        </div>
      )}
    </div>
  );
}
