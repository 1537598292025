import React, { useContext } from "react";
import { CostomContext } from "../../../../_context.jsx";

export default function Pet() {
  const { orderServices, petAlert, setOrderServices } =
    useContext(CostomContext);

  return (
    <>
      <div
        className={
          orderServices.pet
            ? " checkbox bathhouse opencheckbox--show barbecue"
            : "checkbox bathhouse barbecue close--bathhouse"
        }
      >
        <div className="alert--checkbox">
          <input
            type="checkbox"
            name="pet"
            id="pet"
            checked={orderServices.pet}
            onChange={(e) => {
              if (e.target.checked) {
                petAlert();
              }
              setOrderServices({
                ...orderServices,
                pet: e.target.checked,
              });
            }}
          />
          <label className="alert--pet" htmlFor="pet">
            Имеется&nbsp;домашний&nbsp;питомец
          </label>
          <span className="alert--question" onClick={() => petAlert()}>
            ?
          </span>
        </div>
      </div>
      {orderServices.pet && (
        <div className="form--active greeting--form pet">
          <span>
            {`Мы любим животных, и понимаем, как Вы любите своего домашнего
            питомца. Поэтому в некоторые наши дома мы можем пустить небольших
            животных при условии соблюдения хозяевами `}
            <a
              className="greeting--name"
              href="https://lukino.ru/tour/docs/animal_terms_lukino.pdf"
              target="_blank"
              rel="noreferrer noopener"
            >
              этих правил.
            </a>
          </span>
        </div>
      )}
    </>
  );
}
