import React, { useContext } from "react";
import { CostomContext } from "../../../../_context.jsx";

export default function Pavilion() {
  const { setAddFields, pavilion, LISTTIME, setPavilion, dateResidence } =
    useContext(CostomContext);
  return (
    <>
      <div
        className={
          pavilion.active
            ? "bathhouse opencheckbox--show"
            : "bathhouse close--bathhouse"
        }
      >
        <div className="checkbox">
          <input
            type="checkbox"
            name="pavilion"
            id="pavilion"
            checked={pavilion.active}
            onChange={(e) => {
              setPavilion({
                ...pavilion,
                active: e.target.checked,
              });
            }}
          />
          <label htmlFor="pavilion">Забронировать беседку</label>
        </div>
      </div>
      {pavilion.active && (
        <>
          <div className="checkbox--time bathhouse--date bathhouse_sauna massage--date">
            <div className="bathhouse--date--day">
              <span>Дата:</span>
              <input
                value={pavilion.date}
                className="input"
                type="date"
                name="pavilion_date"
                id="pavilion_date"
                min={dateResidence.From}
                max={dateResidence.To}
                required
                onChange={(e) =>
                  setPavilion({
                    ...pavilion,
                    date: e.target.value,
                  })
                }
              />
            </div>
            <div className="bathhouse--date--time">
              <div>Время:</div>
              <div className="bathhouse--time">
                <input
                  list="inputs_time1"
                  type="time"
                  min="09:00"
                  max="22:00"
                  className="input input_time bathhouse_time"
                  name="pavilion_time"
                  placeholder={pavilion.time}
                  value={pavilion.time}
                  maxLength="5"
                  required="req"
                  pattern="^([0-1]\d|2[0-3])(:00)?$"
                  onChange={(e) => {
                    setPavilion({
                      ...pavilion,
                      time: e.target.value,
                    });
                  }}
                  onBlur={(e) => {
                    if (e.target.value.length > 4) {
                      setPavilion({
                        ...pavilion,
                        time:
                          e.target.value[0] +
                          e.target.value[1] +
                          e.target.value[2] +
                          "00",
                      });
                    }
                    if (e.target.value.length <= 2) {
                      if (e.target.value.length === 1) {
                        setPavilion({
                          ...pavilion,
                          time: "0" + e.target.value + ":00",
                        });
                      } else {
                        setPavilion({
                          ...pavilion,
                          time: e.target.value + ":00",
                        });
                      }
                    }
                  }}
                />
                <datalist id="inputs_time1">{LISTTIME}</datalist>
              </div>
            </div>
          </div>
          <div className="pavilion--label">
            Выберете подходящую для вас беседку
          </div>
          <div
            className={
              pavilion.active
                ? "bathhouse opencheckbox--show"
                : "bathhouse close--bathhouse"
            }
          >
            <div className="checkbox pavilion--checkbox">
              <input
                type="checkbox"
                name="smallPavilion"
                id="smallPavilion"
                checked={pavilion.smallPavilion}
                onChange={(e) => {
                  setPavilion({
                    ...pavilion,
                    smallPavilion: e.target.checked,
                    afloatPavilion: false,
                    averagePavilion: false,
                    bigPavilion: false,
                  });
                }}
              />
              <label htmlFor="smallPavilion">Малая беседка</label>
              <span
                className="alert--question massage__type--alert"
                data-title="2-4 человека. 3 часа."
              >
                ?
              </span>
            </div>
            <div className="checkbox pavilion--checkbox">
              <input
                type="checkbox"
                name="averagePavilion"
                id="averagePavilion"
                checked={pavilion.averagePavilion}
                onChange={(e) => {
                  setPavilion({
                    ...pavilion,
                    averagePavilion: e.target.checked,
                    afloatPavilion: false,
                    smallPavilion: false,
                    bigPavilion: false,
                  });
                }}
              />
              <label htmlFor="averagePavilion">Средняя беседка</label>
              <span
                className="alert--question massage__type--alert"
                data-title="2-6 человек. 3 часа."
              >
                ?
              </span>
            </div>
            <div className="checkbox pavilion--checkbox">
              <input
                type="checkbox"
                name="afloatPavilion"
                id="afloatPavilion"
                checked={pavilion.afloatPavilion}
                onChange={(e) => {
                  setPavilion({
                    ...pavilion,
                    afloatPavilion: e.target.checked,
                    averagePavilion: false,
                    smallPavilion: false,
                    bigPavilion: false,
                  });
                }}
              />
              <label htmlFor="afloatPavilion">Беседка на воде</label>
              <span
                className="alert--question massage__type--alert"
                data-title="2-4 человека. 3 часа."
              >
                ?
              </span>
            </div>
            <div className="checkbox pavilion--checkbox">
              <input
                type="checkbox"
                name="bigPavilion"
                id="bigPavilion"
                checked={pavilion.bigPavilion}
                onChange={(e) => {
                  setPavilion({
                    ...pavilion,
                    bigPavilion: e.target.checked,
                    afloatPavilion: false,
                    averagePavilion: false,
                    smallPavilion: false,
                  });
                }}
              />
              <label htmlFor="bigPavilion">Большая беседка</label>
              <span
                className="alert--question massage__type--alert"
                data-title="6-12 человек. 3 часа."
              >
                ?
              </span>
            </div>
          </div>
        </>
      )}
    </>
  );
}
