import React, { useContext } from "react";
import { CostomContext } from "../../_context.jsx";
import "./_formAdd.scss";
import "../_inputs.scss";
import "../_buttons.scss";
import Alert from "../alert/_alert.jsx";
import Modal from "../modal/modal.jsx";
import Greeting from "../modules/greeting/greeting.jsx";
import Accommodation from "../modules/accommodation/accommodation.jsx";
import Gastronomic from "../modules/gastronomic--preferences/gactronomic.jsx";
import BeautyHealth from "../modules/beauty--health/beautyHealth.jsx";
import RestWell from "../modules/rest--well/restwell.jsx";

export default function Middle() {
  const { loadModal, form, search, start } = useContext(CostomContext);

  console.log(search);

  return (
    <>
      {loadModal && <Modal />}
      <Alert />
      <div className="bx24_form form-user">
        {/* <form className="form-user" onSubmit={UpdateData}> */}
        {start ? (
          <>
            {form.form1 && <Greeting />}
            {form.form2 && <Accommodation />}
            {form.form3 && <Gastronomic />}
            {form.form4 && <BeautyHealth />}
            {form.form5 && <RestWell />}
          </>
        ) : (
          <Greeting />
        )}
        {/* {search === "?next=10" && <Bathhouse />}
        {search === "?next=11" && (
          <>
            <div
              className={
                gazebos.active
                  ? "bathhouse opencheckbox--show"
                  : "bathhouse close--bathhouse"
              }
            >
              <div className="checkbox">
                <input
                  type="checkbox"
                  name="bathhouse_gazebos"
                  id="bathhouse_gazebos"
                  checked={gazebos.active}
                  onChange={(e) => {
                    setGazebos({ ...gazebos, active: e.target.checked });
                  }}
                />
                <label htmlFor="bathhouse_gazebos">Забронировать беседку</label>
              </div>
              {gazebos.active && (
                <div className="checkbox--time">
                  <div>Время:</div>
                  <div className="bathhouse--time">
                    <input
                      list="inputs_time3"
                      className="input input_time"
                      type="time"
                      min="09:00"
                      max="22:00"
                      pattern="^([0-1]\d|2[0-3])(:00)?$"
                      name="bathhouse_gazebos_time"
                      value={gazebos.time}
                      onChange={(e) =>
                        setGazebos({ ...gazebos, time: e.target.value })
                      }
                      onBlur={(e) => {
                        if (e.target.value.length > 4) {
                          setGazebos({
                            ...gazebos,
                            time:
                              e.target.value[0] +
                              e.target.value[1] +
                              e.target.value[2] +
                              "00",
                          });
                        }
                        if (e.target.value.length <= 2) {
                          if (e.target.value.length === 1) {
                            setGazebos({
                              ...gazebos,
                              time: "0" + e.target.value + ":00",
                            });
                          } else {
                            setGazebos({
                              ...gazebos,
                              time: e.target.value + ":00",
                            });
                          }
                        }
                      }}
                    />
                    <datalist id="inputs_time3">{LISTTIME}</datalist>
                  </div>
                </div>
              )}
            </div>

            <div className="div--form">
              <div>Прочие услуги:</div>
              <div className="form__otherServices">
                <div
                  className={
                    orderServices.manicure
                      ? "checkbox opencheckbox--show bathhouse"
                      : "checkbox bathhouse close--bathhouse"
                  }
                >
                  <input
                    type="checkbox"
                    name="manicure"
                    id="manicure"
                    checked={orderServices.manicure}
                    onChange={(e) => {
                      setOrderServices({
                        ...orderServices,
                        manicure: e.target.checked,
                      });
                    }}
                  />
                  <label htmlFor="manicure">Требуется маникюр</label>
                </div>
                <div
                  className={
                    orderServices.businessExcursion
                      ? "checkbox opencheckbox--show bathhouse"
                      : "checkbox bathhouse close--bathhouse"
                  }
                >
                  <input
                    type="checkbox"
                    name="businessExcursion"
                    id="businessExcursion"
                    checked={orderServices.businessExcursion}
                    onChange={(e) => {
                      setOrderServices({
                        ...orderServices,
                        businessExcursion: e.target.checked,
                      });
                    }}
                  />
                  <label htmlFor="businessExcursion">
                    Посетить бизнес экскурсию
                  </label>
                </div>
              </div>
            </div>
            <AdditionalFields />
            <div className="form-btn">
              <button className="btn btn-primary" type="submit">
                <span>Подтвердить</span>
              </button>
            </div>
          </>
        )} */}
        {/* </form> */}
      </div>
    </>
  );
}
