import React, { useContext } from "react";
import { CostomContext } from "../../../../../_context.jsx";

export default function BroomAromatic() {
  const { bathhouse, setBathhouse } = useContext(CostomContext);

  return (
    <>
      <div className="checkbox--time extraSpace">
        <div>Полынных веников:</div>
        <div className="bathhouse--time">
          <input
            className="input input_time extraSpace_range"
            type="number"
            min="0"
            max="10"
            id="broomOrdinary_wormwood"
            name="broomOrdinary_wormwood"
            value={bathhouse.broom.broomAromatic.wormwood}
            onChange={(e) => {
              setBathhouse({
                ...bathhouse,
                broom: {
                  ...bathhouse.broom,
                  broomAromatic: {
                    ...bathhouse.broom.broomAromatic,
                    wormwood: e.target.value,
                  },
                },
              });
            }}
          />
          <div
            className="item__add"
            onClick={() => {
              if (bathhouse.broom.broomAromatic.wormwood < 10)
                setBathhouse({
                  ...bathhouse,
                  broom: {
                    ...bathhouse.broom,
                    broomAromatic: {
                      ...bathhouse.broom.broomAromatic,
                      wormwood: bathhouse.broom.broomAromatic.wormwood + 1,
                    },
                  },
                });
            }}
          >
            ‹
          </div>
          <div
            className="item__remove"
            onClick={() => {
              if (bathhouse.broom.broomAromatic.wormwood > 0)
                setBathhouse({
                  ...bathhouse,
                  broom: {
                    ...bathhouse.broom,
                    broomAromatic: {
                      ...bathhouse.broom.broomAromatic,
                      wormwood: bathhouse.broom.broomAromatic.wormwood - 1,
                    },
                  },
                });
            }}
          >
            ‹
          </div>
        </div>
      </div>
      <div className="checkbox--time extraSpace">
        <div>Донниковых веников:</div>
        <div className="bathhouse--time">
          <input
            className="input input_time extraSpace_range"
            type="number"
            min="0"
            max="10"
            id="broomOrdinary_tributary"
            name="broomOrdinary_tributary"
            value={bathhouse.broom.broomAromatic.tributary}
            onChange={(e) => {
              setBathhouse({
                ...bathhouse,
                broom: {
                  ...bathhouse.broom,
                  broomAromatic: {
                    ...bathhouse.broom.broomAromatic,
                    tributary: e.target.value,
                  },
                },
              });
            }}
          />
          <div
            className="item__add"
            onClick={() => {
              if (bathhouse.broom.broomAromatic.tributary < 10)
                setBathhouse({
                  ...bathhouse,
                  broom: {
                    ...bathhouse.broom,
                    broomAromatic: {
                      ...bathhouse.broom.broomAromatic,
                      tributary: bathhouse.broom.broomAromatic.tributary + 1,
                    },
                  },
                });
            }}
          >
            ‹
          </div>
          <div
            className="item__remove"
            onClick={() => {
              if (bathhouse.broom.broomAromatic.tributary > 0)
                setBathhouse({
                  ...bathhouse,
                  broom: {
                    ...bathhouse.broom,
                    broomAromatic: {
                      ...bathhouse.broom.broomAromatic,
                      tributary: bathhouse.broom.broomAromatic.tributary - 1,
                    },
                  },
                });
            }}
          >
            ‹
          </div>
        </div>
      </div>
      <div className="checkbox--time extraSpace">
        <div>Мятных веников:</div>
        <div className="bathhouse--time">
          <input
            className="input input_time extraSpace_range"
            type="number"
            min="0"
            max="10"
            id="broomOrdinary_mata"
            name="broomOrdinary_mata"
            value={bathhouse.broom.broomAromatic.mata}
            onChange={(e) => {
              setBathhouse({
                ...bathhouse,
                broom: {
                  ...bathhouse.broom,
                  broomAromatic: {
                    ...bathhouse.broom.broomAromatic,
                    mata: e.target.value,
                  },
                },
              });
            }}
          />
          <div
            className="item__add"
            onClick={() => {
              if (bathhouse.broom.broomAromatic.mata < 10)
                setBathhouse({
                  ...bathhouse,
                  broom: {
                    ...bathhouse.broom,
                    broomAromatic: {
                      ...bathhouse.broom.broomAromatic,
                      mata: bathhouse.broom.broomAromatic.mata + 1,
                    },
                  },
                });
            }}
          >
            ‹
          </div>
          <div
            className="item__remove"
            onClick={() => {
              if (bathhouse.broom.broomAromatic.mata > 0)
                setBathhouse({
                  ...bathhouse,
                  broom: {
                    ...bathhouse.broom,
                    broomAromatic: {
                      ...bathhouse.broom.broomAromatic,
                      mata: bathhouse.broom.broomAromatic.mata - 1,
                    },
                  },
                });
            }}
          >
            ‹
          </div>
        </div>
      </div>
    </>
  );
}
