import "./App.css";
import React from "react";
import Content from "./Content/content";
import { BrowserRouter as Router} from "react-router-dom";

function App() {
  return (
    <Router>
      <Content />
    </Router>
  );
}

export default App;
