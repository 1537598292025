import React, { useContext } from "react";
import { CostomContext } from "../../../_context.jsx";
import "./bathhouse.scss";
import Broom from "./broom/broom.jsx";

export default function Bathhouse() {
  const {
    LISTTIME,
    bathhouse,
    setBathhouse,
    dateResidence,
    chernyean,
    setChernyean,
  } = useContext(CostomContext);
  const AddTime = (elements) => {
    setBathhouse({
      ...bathhouse,
      time: elements,
    });
  };
  const AddTimeChernyean = (elemets) => {};

  return (
    <div
      style={{ marginBottom: "1rem" }}
      className={
        bathhouse.active
          ? "bathhouse_sauna opencheckbox--show bathhouse_sauna--active"
          : "bathhouse_sauna close--bathhouse"
      }
    >
      <div className="checkbox">
        <input
          type="checkbox"
          name="bathhouse_sauna"
          id="bathhouse_sauna"
          checked={bathhouse.active}
          onChange={(e) => {
            setBathhouse({ ...bathhouse, active: e.target.checked });
          }}
        />
        <label htmlFor="bathhouse_sauna">Забронировать баню</label>
      </div>
      {bathhouse.active && (
        <>
          <div className="checkbox--time bathhouse--date">
            <div className="bathhouse--date--day">
              <span>Дата:</span>
              <input
                value={bathhouse.date}
                className="input"
                type="date"
                name="dateFrom_user"
                id="dateFrom_user"
                min={dateResidence.From}
                max={dateResidence.To}
                required
                onChange={(e) =>
                  setBathhouse({ ...bathhouse, date: e.target.value })
                }
              />
            </div>
            <div className="bathhouse--date--time">
              <div>Время:</div>
              <div className="bathhouse--time">
                <input
                  list="inputs_time1"
                  type="time"
                  min="09:00"
                  max="22:00"
                  className="input input_time bathhouse_time"
                  name="bathhouse_sauna_time"
                  placeholder={bathhouse.time}
                  value={bathhouse.time}
                  maxLength="5"
                  required="req"
                  pattern="^([0-1]\d|2[0-3])(:00)?$"
                  onChange={(e) => {
                    setBathhouse({ ...bathhouse, time: e.target.value });
                  }}
                  onBlur={(e) => {
                    if (e.target.value.length > 4)
                      AddTime(
                        e.target.value[0] +
                          e.target.value[1] +
                          e.target.value[2] +
                          "00"
                      );
                    if (e.target.value.length <= 2) {
                      if (e.target.value.length === 1)
                        AddTime("0" + e.target.value + ":00");
                      else AddTime(e.target.value + ":00");
                    }
                  }}
                />
                <datalist id="inputs_time1">{LISTTIME}</datalist>
              </div>
            </div>
          </div>
          <div className="greeting--form" style={{ margin: "2rem 0 1rem" }}>
            Опытный банщик знает, что париться без веника скучно и не так
            душевно. Поэтому мы предлагаем на выбор березовые и дубовые веники,
            которые будут ждать Вас в бане готовыми к употреблению.
          </div>
          <Broom />
          <div>
            <div
              className={
                bathhouse.drinkAndTreats.active
                  ? "checkbox opencheckbox--show "
                  : "checkbox close--bathhouse"
              }
            >
              <input
                type="checkbox"
                name="bathhouse_drinkAndTreats"
                id="bathhouse_drinkAndTreats"
                checked={bathhouse.drinkAndTreats.active}
                onChange={(e) => {
                  setBathhouse({
                    ...bathhouse,
                    drinkAndTreats: {
                      ...bathhouse.drinkAndTreats,
                      active: e.target.checked,
                    },
                  });
                }}
              />
              <label htmlFor="bathhouse_drinkAndTreats">
                Напитки и угощения (Чай. Морс. Мед.)
              </label>
            </div>
            {bathhouse.drinkAndTreats.active && (
              <div className="bathhouse--drinkAndTreats">
                <textarea
                  className="textarea input_time"
                  placeholder="Пожалуйста укажите все, что Вы хотите видеть на столе в бане, но помните о здоровье."
                  name="banquet_text"
                  value={bathhouse.drinkAndTreats.order}
                  onChange={(e) => {
                    setBathhouse({
                      ...bathhouse,
                      drinkAndTreats: {
                        ...bathhouse.drinkAndTreats,
                        order: e.target.value,
                      },
                    });
                  }}
                />
              </div>
            )}
            <div style={{ marginTop: "1rem" }}>
              Банный чан на дровах прекрасен в любое время года и в любую
              погоду, но особое удовольствие от него получаешь, когда вокруг
              ветер, дождь, а еще лучше - метель. Вот тогда ты в полной мере
              ощущаешь комфорт и уют чана, в который можно спрятаться по самые
              глаза. Наш чан вмещает до 6 человек.
            </div>
            <div
              className={
                chernyean.active
                  ? "bathhouse opencheckbox--show"
                  : "bathhouse close--bathhouse"
              }
            >
              <div className="checkbox">
                <input
                  type="checkbox"
                  name="bathhouse_chernyean"
                  id="bathhouse_chernyean"
                  checked={chernyean.active}
                  onChange={(e) => {
                    setChernyean({
                      ...chernyean,
                      active: e.target.checked,
                    });
                  }}
                />
                <label htmlFor="bathhouse_chernyean">
                  Забронировать банный чан
                </label>
              </div>
              {chernyean.active && (
                <div className="checkbox--time" style={{ display: "flex" }}>
                  <div>Время:</div>
                  <div className="bathhouse--time">
                    <input
                      list="inputs_time2"
                      className="input input_time"
                      type="time"
                      min="09:00"
                      max="22:00"
                      name="bathhouse_chernyean_time"
                      value={chernyean.time}
                      pattern="^([0-1]\d|2[0-3])(:00)?$"
                      onChange={(e) =>
                        setChernyean({
                          ...chernyean,
                          time: e.target.value,
                        })
                      }
                      onBlur={(e) => {
                        if (e.target.value.length > 4)
                          AddTimeChernyean(
                            e.target.value[0] +
                              e.target.value[1] +
                              e.target.value[2] +
                              "00"
                          );
                        if (e.target.value.length <= 2) {
                          if (e.target.value.length === 1)
                            AddTimeChernyean("0" + e.target.value + ":00");
                          else AddTimeChernyean(e.target.value + ":00");
                        }
                      }}
                    />
                    <datalist id="inputs_time2">{LISTTIME}</datalist>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
