import React, { useContext } from "react";
import { CostomContext } from "../../../_context.jsx";
import "./gastronomic.scss";
import { Link, useNavigate } from "react-router-dom";
import Meal from "./meal/meal.jsx";
import Banquet from "./banquet/banquet.jsx";
/* ПостНОвкусное меняю меню
<a
style={{ paddingLeft: "0.3rem" }}
className="greeting--name"
href="https://tour.lukino.ru/veg-menu.pdf"
target="_blank"
rel="noreferrer noopener"
>
{"постНОвкусным меню."}
</a>
*/

function MenuSrc() {
  return (
    <div className="accommodation--form">
      <div className="accommodation--form">
        <span className="greeting--form">
          Без предварительного заказа некоторые блюда в зависимости от загрузки
          кухни могут готовиться до 1 часа.
        </span>
      </div>
      {"Вы можете ознакомиться с нашим "}
      <a
        style={{ paddingLeft: "0.3rem" }}
        className="greeting--name"
        href="https://tour.lukino.ru/menu.pdf"
        target="_blank"
        rel="noreferrer noopener"
      >
        {"меню для взрослых,"}
      </a>{" "}
      <a
        style={{ paddingLeft: "0.3rem" }}
        className="greeting--name"
        href="https://tour.lukino.ru/menu-detskoe.jpg"
        target="_blank"
        rel="noreferrer noopener"
      >
        {"меню для детей"}
      </a>
    </div>
  );
}

export default function Gastronomic() {
  const { PATCHNAME, form, setForm } = useContext(CostomContext);

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    navigate("/" + PATCHNAME + "?next=3");
  };

  return (
    <form onSubmit={handleSubmit}>
      <div
        className={
          form.end3
            ? "div--form end--active"
            : form.form3
            ? "div--form form--active"
            : "div--form"
        }
      >
        <span className="greeting--form">
          Мы готовим преимущественно из наших фермерских продуктов без запасов,
          только под заказ. Поэтому просим составить заказ на ПЕРВЫЙ обед или
          ужин за день до приезда в Лукино, и указать время, когда Вы желаете
          сесть за стол. Так мы успеем подготовить все наилучшим образом.
        </span>
        <MenuSrc />
        <Meal />
        <Banquet />
        <div className="form-btn">
          <Link
            to={"/" + PATCHNAME + "?next=1"}
            style={{ textDecoration: "none !important" }}
          >
            <button
              className="btn btn-secondary"
              type="button"
              onClick={() => setForm({ ...form, end3: true })}
            >
              <span>Назад</span>
            </button>
          </Link>
          <button className="btn btn-primary" type="submit">
            <span>Далее</span>
          </button>
        </div>
      </div>
    </form>
  );
}
