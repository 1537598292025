import React, { useContext } from "react";
import { CostomContext } from "../../../_context.jsx";
import "./beautyHealth.scss";
import { Link, useNavigate } from "react-router-dom";
import Bathhouse from "../bathhouse/bathhouse.jsx";
import Massage from "./massage/massage.jsx";

export default function BeautyHealth() {
  const { PATCHNAME, form, setForm } = useContext(CostomContext);

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    navigate("/" + PATCHNAME + "?next=4");
  };

  return (
    <form onSubmit={handleSubmit}>
      <div
        className={
          form.end4
            ? "div--form end--active"
            : form.form4
            ? "div--form form--active"
            : "div--form"
        }
      >
        <div className="greeting--form" style={{ marginBottom: "1rem" }}>
          В Лукино баня — это не просто парилка. Мы создали настоящий
          мини-спа-центр, в котором есть русская парная на дровах, хамам,
          гидромассажная ванная, чан с горячей водой, а также все необходимое
          для массажа и пилинга.
          <div>
            Если Вы желаете забронировать банный чан, вам необходимо
            активировать бронь на баню.
          </div>
        </div>
        <Bathhouse />
        <Massage />
        <div className="form-btn">
          <Link
            to={"/" + PATCHNAME + "?next=2"}
            style={{ textDecoration: "none !important" }}
          >
            <button
              className="btn btn-secondary"
              type="button"
              onClick={() => setForm({ ...form, end4: true })}
            >
              <span>Назад</span>
            </button>
          </Link>
            <button className="btn btn-primary" type="submit">
              <span>Далее</span>
            </button>
        </div>
      </div>
    </form>
  );
}
