import React, { useState, useContext, useEffect } from "react";
import { CostomContext } from "../../../../../_context.jsx";

export default function TypeOfMassage({ ID, TITLE, data, seans }) {
  const [active, setActive] = useState(false);
  const { setOrderServices, orderServices } = useContext(CostomContext);
  console.log(orderServices);
  useEffect(() => {
    setActive(
      orderServices.massage.typeMassage.filter((el) => el.active === ID)
        .length > 0
        ? true
        : false
    );
  }, []);
  return (
    <div className="massage__type--inner">
      <div className="checkbox massage__type">
        <input
          type="checkbox"
          name={"massage" + ID}
          id={"massage" + ID}
          checked={active}
          onChange={(e) => {
            if (e.target.checked) {
              orderServices.massage.typeMassage.push({ active: ID });
              console.log(orderServices);
            } else {
              const newtypeMassage = orderServices.massage.typeMassage.filter(
                (el) => el.active !== ID
              );
              setOrderServices({
                ...orderServices,
                massage: {
                  ...orderServices.massage,
                  typeMassage: newtypeMassage,
                },
              });
              console.log(orderServices);
            }
            setActive(e.target.checked);
          }}
        />
        <label htmlFor={"massage" + ID}>
          <div className="massage__type--title">{TITLE + " "}</div>
        </label>
        <span
          className="alert--question massage__type--alert"
          data-title={data}
        >
          ?
        </span>
      </div>
      <div className="massage__type--seans">Продолжительность {seans}</div>
    </div>
  );
}
