import React, { useContext } from "react";
import { CostomContext } from "../../../../_context.jsx";

export default function Wishes() {
  const { addFields, setAddFields } = useContext(CostomContext);
  return (
    <div
      className={
        addFields.houseWishes.active
          ? "bathhouse opencheckbox--show barbecue"
          : "bathhouse barbecue close--bathhouse"
      }
    >
      <div className="checkbox">
        <input
          type="checkbox"
          name="houseWishes"
          id="houseWishes"
          checked={addFields.houseWishes.active}
          onChange={(e) => {
            setAddFields({
              ...addFields,
              houseWishes: {
                ...addFields.houseWishes,
                active: e.target.checked,
              },
            });
          }}
        />
        <label htmlFor="houseWishes">
          Дополнительные пожелания по проживанию
        </label>
      </div>
      {addFields.houseWishes.active && (
        <div className="checkbox--time barbecue">
          <div className="bathhouse--time">
            <textarea
              className="textarea input_time"
              name="houseWishes_text"
              placeholder="Пожалуйста, укажите ваши пожелания, которые необходимо предусмотреть."
              value={addFields.houseWishes.text}
              onChange={(e) => {
                setAddFields({
                  ...addFields,
                  houseWishes: {
                    ...addFields.houseWishes,
                    text: e.target.value,
                  },
                });
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
