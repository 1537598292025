import React, { createContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

export const CostomContext = createContext();

export const Context = (props) => {
  const LISTTIME = (
    <>
      <option value={"09:00"} />
      <option value={"10:00"} />
      <option value={"11:00"} />
      <option value={"12:00"} />
      <option value={"13:00"} />
      <option value={"14:00"} />
      <option value={"15:00"} />
      <option value={"16:00"} />
      <option value={"17:00"} />
      <option value={"18:00"} />
      <option value={"19:00"} />
      <option value={"20:00"} />
      <option value={"21:00"} />
      <option value={"22:00"} />
    </>
  );
  const [bathhouse, setBathhouse] = useState({
    active: false,
    time: "09:00",
    date: "",
    broom: {
      active: false,
      broomOrdinary: { active: false, oak: 0, birch: 0 },
      broomAromatic: { active: false, wormwood: 0, tributary: 0, mata: 0 },
    },
    drinkAndTreats: { active: false, order: "" },
  }); //готово
  const [load, setLoad] = useState(false); //готово
  const [start, setStart] = useState(false); //готово
  const [chernyean, setChernyean] = useState({ active: false, time: "09:00" }); //готово
  const [gazebos, setGazebos] = useState({ active: false, time: "09:00" }); //готово
  const [crib, setCrib] = useState({ active: false, count: 1 }); //готово
  const [pavilion, setPavilion] = useState({
    active: false,
    date: "2021-01-01",
    time: "09:00",
    smallPavilion: false,
    averagePavilion: false,
    afloatPavilion: false,
    bigPavilion: false,
  }); //готово
  const [orderServices, setOrderServices] = useState({
    pet: false,
    massage: {
      active: false,
      date: "2021-01-01",
      time: "09:00",
      location: { bathComplex: false, room: false },
      typeMassage: [],
    },
    manicure: false,
    businessExcursion: false,
  }); //готово
  const [check, setCheck] = useState({
    checkIn: { active: false, time: "09:00" },
    checkOut: { active: false, time: "09:00" },
  });
  const [house, setHouse] = useState("Загрузка данных...");
  const [FIO, setFIO] = useState("Загрузка данных...");
  const [bookingNumber, setBookingNumber] = useState("Загрузка данных...");

  // Переменные Алерт
  const [AlertText, setAlertText] = useState("");
  const [show_on, setShow_on] = useState(false);
  const [tops, setTops] = useState("5rem");

  const [loadModal, setLoadModal] = useState(false);

  const [dateResidence, setDateResidence] = useState({
    From: "2021-01-01",
    To: "2021-01-01",
  });

  // onChange={(e) => setMeal(e.target.value)}
  const [addFields, setAddFields] = useState({
    arrivalTime: "00:00", //готово
    meal: { active: false, time: "00:00", text: '' }, //готово
    barbecue: { active: false, text: "" }, //готово
    banquet: { active: false, text: "", date: "2021-01-01", count: 0 }, //готово
    houseWishes: { active: false, text: "" }, //готово
    extraSpace: { active: false, count: 1 }, //готово
  });

  const [form, setForm] = useState({
    form1: false,
    end1: false,
    form2: false,
    end2: false,
    form3: false,
    end3: false,
    form4: false,
    end4: false,
    form5: false,
    end5: false,
  });
  const locations = useLocation();
  const search = locations.search;
  useEffect(() => {
    if (search === "?next=1") {
      setForm({ ...form, end1: true });
      setTimeout(
        () =>
          setForm({
            form1: false,
            end1: false,
            form2: true,
            end2: false,
            form3: false,
            end3: false,
            form4: false,
            end4: false,
            form5: false,
            end5: false,
          }),
        700
      );
    }
    if (search === "?next=2") {
      setForm({ ...form, end2: true });
      setTimeout(
        () =>
          setForm({
            form1: false,
            end1: false,
            form2: false,
            end2: false,
            form3: true,
            end3: false,
            form4: false,
            end4: false,
            form5: false,
            end5: false,
          }),
        700
      );
    }
    if (search === "?next=3") {
      setForm({ ...form, end3: true });
      setTimeout(
        () =>
          setForm({
            form1: false,
            end1: false,
            form2: false,
            end2: false,
            form3: false,
            end3: false,
            form4: true,
            end4: false,
            form5: false,
            end5: false,
          }),
        700
      );
    }
    if (search === "?next=4") {
      setForm({ ...form, end4: true });
      setTimeout(
        () =>
          setForm({
            form1: false,
            end1: false,
            form2: false,
            end2: false,
            form3: false,
            end3: false,
            form4: false,
            end4: false,
            form5: true,
            end5: false,
          }),
        700
      );
    }
    if (search === "") {
      setForm({ ...form, end2: true });
      setTimeout(
        () =>
          setForm({
            form1: true,
            end1: false,
            form2: false,
            end2: false,
            form3: false,
            end3: false,
            form4: false,
            end4: false,
            form5: false,
            end5: false,
          }),
        700
      );
    }
  }, [search]);
  const PATCHNAME = locations.pathname.split("/")[1];

  useEffect(() => {
    if (!bathhouse.active) {
      setChernyean({ ...chernyean, active: false });
    }
  }, [bathhouse.active]);

  useEffect(() => {
    if (!load) {
      fetch(
      "https://survey.guest.lukino.ru/api/bookingUser/" +
      // fetch(
      //   "http://localhost:3001/api/bookingUser/" +
          locations.pathname.split("/")[1]
      )
        .then((response) => response.json())
        .then((res) => {
          console.log(res)
          setLoad(true);
          setDateResidence({ From: res.DATE_FROM, To: res.DATE_TO });
          setBookingNumber(res.TITLE);
          setHouse(res.HOUSE_ROOM);
          if (res.crib > 0) {
            setCrib({ active: true, count: res.crib });
          } else {
            setCrib({ active: false, count: +res.crib });
          }
          setFIO(res.USERNAME);
          bathhouse.time = res.bathhouseTime ? res.bathhouseTime : "09:00";
          if (res.bathhouseDate) {
            bathhouse.date = res.bathhouseDate;
          }
          setBathhouse({
            ...bathhouse,
            date: res.bathhouseDate,
            active: res.bathhouse,
            broom: {
              active: res.broom,
              broomOrdinary: {
                active: res.broomOrdinary,
                oak: res.oak,
                birch: res.birch,
              },
              broomAromatic: {
                active: res.broomAromatic,
                wormwood: res.wormwood,
                tributary: res.tributary,
                mata: res.mata,
              },
            },
            drinkAndTreats: res.drinkAndTreatsOrder && {
              active: true,
              order: res.drinkAndTreatsOrder,
            },
          });
          setChernyean({
            active: res.chernyean,
            time: res.chernyeanTime ? res.chernyeanTime : "09:00",
          });
          setGazebos({
            active: res.gazebos,
            time: res.gazebosTime ? res.gazebosTime : "09:00",
          });
          setOrderServices({
            pet: res.pet,
            massage: {
              active: res.massageActive,
              date: res.massageDate,
              time: res.massageTime,
              location: {
                bathComplex: res.massageBathComplex,
                room: res.massageRoom,
              },
              typeMassage: res.typeMassage,
            },
            manicure: res.manicure,
            businessExcursion: res.businessExcursion,
          });
          addFields.arrivalTime = res.addivalTime;
          setAddFields({
            arrivalTime: res.addivalTime ? res.addivalTime : "00:00",
            ...addFields,
            meal: {
              ...addFields.meal,
              active: res.meal,
              time: res.mealTime ? res.mealTime : "09:00",
              text: res.mealText ? res.mealText : '',
            },
            barbecue: {
              ...addFields.barbecue,
              active: res.barbecue,
              text: res.barbecueText,
            },
            banquet: {
              ...addFields.banquet,
              active: res.banquet,
              text: res.banquetText,
              date: res.banquetDate,
              count: res.banquetCount,
            },
            extraSpace: {
              ...addFields.extraSpace,
              active: res.extraSpace,
              count: res.extraSpaceCount,
            },
            houseWishes: !!res.houseWishesText && {
              active: true,
              text: res.houseWishesText,
            },
          });
          setCheck({
            ...check,
            checkIn: {
              ...check.checkIn,
              active: res.checkInActive,
              time: res.checkInTime ? res.checkInTime : "09:00",
            },
            checkOut: {
              ...check.checkOut,
              active: res.checkOutActive,
              time: res.checkOutTime ? res.checkOutTime : "09:00",
            },
          });
          setPavilion({
            ...pavilion,
            active: res.pavilionActive,
            date: res.pavilionDate,
            time: res.pavilionTime,
            smallPavilion: res.pavilionSmallPavilion,
            averagePavilion: res.pavilionAveragePavilion,
            afloatPavilion: res.pavilionAfloatPavilion,
            bigPavilion: res.pavilionBigPavilion,
          });
        })
        .catch((err) => console.log("err: ", err));
    }
  }, [load, locations.pathname]);

  const UpdateData = (event) => {
    fetch(
    "https://survey.guest.lukino.ru/api/bookingUpdate",
      // fetch("http://localhost:3001/api/bookingUpdate",
        {
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      method: "PUT",
      body: JSON.stringify({
        id: locations.pathname.split("/")[1],
        bathhouse: bathhouse,
        chernyean: chernyean,
        gazebos: gazebos,
        crib: crib,
        orderServices: orderServices,
        addFields: addFields,
        check: check,
        pavilion: pavilion,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        const aa = {
          id: locations.pathname.split("/")[1],
          bathhouse: bathhouse,
          chernyean: chernyean,
          gazebos: gazebos,
          crib: crib,
          orderServices: orderServices,
          addFields: addFields,
        };
        if (res.status == "ok" || res.status == "201") setLoadModal(true);
      }).catch((err) => console.log(err));
    event.preventDefault();
  };

  useEffect(() => {
    if (loadModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [loadModal]);

  useEffect(() => {
    document.title = "Анкета гостя Лукино №: " + bookingNumber;
  }, [bookingNumber]);

  const toDay = new Date();
  const dates =
    toDay.getFullYear() +
    "-" +
    (toDay.getMonth() + 1 < 10
      ? "0" + toDay.getMonth() + 1
      : toDay.getMonth() + 1) +
    "-" +
    (toDay.getDate() < 10 ? "0" + toDay.getDate() : toDay.getDate());

  const massageAlert = () => {
    setAlertText("Необходима предварительная запись. Уточните у менеджера.");
    setShow_on(true);
    setTimeout(() => {
      setShow_on(false);
    }, 3500);
  };
  const petAlert = () => {
    setAlertText(
      "Проживание с питомцев возможно не во всех домах. Уточните у менеджера."
    );
    setShow_on(true);
    setTimeout(() => {
      setShow_on(false);
    }, 3500);
  };

  //Данные для вывода
  const value = {
    bookingNumber,
    dateResidence,
    petAlert,
    massageAlert,
    AlertText,
    show_on,
    tops,
    setDateResidence,
    setBathhouse,
    setChernyean,
    setGazebos,
    setHouse,
    setCrib,
    setFIO,
    setOrderServices,
    LISTTIME,
    bathhouse,
    orderServices,
    crib,
    house,
    chernyean,
    gazebos,
    FIO,
    addFields,
    setAddFields,
    dates,
    UpdateData,
    loadModal,
    setLoadModal,
    PATCHNAME,
    form,
    search,
    setCheck,
    check,
    setForm,
    setPavilion,
    pavilion,
    start,
    setStart,
  };

  return (
    <CostomContext.Provider value={value}>
      {props.children}
    </CostomContext.Provider>
  );
};
