import React, { useContext, useEffect, useState } from "react";
import { CostomContext } from "../../../../_context.jsx";
import "./massage.scss";
import TypeOfMassage from "./typeOfMassage/typeOfMassage.jsx";
// import Data from "/data.json";

export default function Massage() {
  const { orderServices, setOrderServices, LISTTIME, dateResidence } =
    useContext(CostomContext);

  const [Data, setData] = useState([]);

  const getData = () => {
    fetch("data.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        console.log(response);
        return response.json();
      })
      .then(function (myJson) {
        console.log(myJson);
        setData(myJson);
      });
  };
  useEffect(() => {
    getData();
  }, []);

  const AddTime = (elements) => {
    setOrderServices({
      ...orderServices,
      massage: {
        ...orderServices.massage,
        time: elements,
      },
    });
  };
  return (
    <>
      <div>
        Дополните свой отдых массажными процедурами, чтобы Ваше тело сказало Вам
        спасибо. Массаж выполняет специалист с медицинским образованием и более
        чем 30-летним стажем.
      </div>
      <div
        className={
          orderServices.massage.active
            ? "checkbox massage opencheckbox--show bathhouse_sauna--active"
            : "checkbox massage close--bathhouse"
        }
      >
        <div className="alert--checkbox">
          <input
            type="checkbox"
            name="massage"
            id="massage"
            checked={orderServices.massage.active}
            onChange={(e) => {
              //   if (e.target.checked) {
              //     massageAlert();
              //   }
              setOrderServices({
                ...orderServices,
                massage: {
                  ...orderServices.massage,
                  active: e.target.checked,
                },
              });
            }}
          />
          <label htmlFor="massage">Требуется массаж</label>
        </div>
      </div>
      {orderServices.massage.active && (
        <>
          <div className="checkbox--time bathhouse--date bathhouse_sauna massage--date">
            <div className="bathhouse--date--day">
              <span>Дата:</span>
              <input
                value={orderServices.massage.date}
                className="input"
                type="date"
                name="bathhouse_sauna_date"
                id="bathhouse_sauna_date"
                min={dateResidence.From}
                max={dateResidence.To}
                required
                onChange={(e) =>
                  setOrderServices({
                    ...orderServices,
                    massage: { ...orderServices.massage, date: e.target.value },
                  })
                }
              />
            </div>
            <div className="bathhouse--date--time">
              <div>Время:</div>
              <div className="bathhouse--time">
                <input
                  list="inputs_time1"
                  type="time"
                  min="09:00"
                  max="22:00"
                  className="input input_time bathhouse_time"
                  name="bathhouse_sauna_time"
                  placeholder={orderServices.massage.time}
                  value={orderServices.massage.time}
                  maxLength="5"
                  required="req"
                  pattern="^([0-1]\d|2[0-3])(:00)?$"
                  onChange={(e) => AddTime(e.target.value)}
                  onBlur={(e) => {
                    if (e.target.value.length > 4)
                      AddTime(
                        e.target.value[0] +
                          e.target.value[1] +
                          e.target.value[2] +
                          "00"
                      );
                    if (e.target.value.length <= 2) {
                      if (e.target.value.length === 1)
                        AddTime("0" + e.target.value + ":00");
                      else AddTime(e.target.value + ":00");
                    }
                  }}
                />
                <datalist id="inputs_time1">{LISTTIME}</datalist>
              </div>
            </div>
          </div>
          <div style={{ margin: "2rem 0 1rem" }}>Выберете место:</div>
          <div className="checkbox--time bathhouse--date bathhouse_sauna massage--date">
            <div className="checkbox">
              <input
                type="checkbox"
                name="bathComplex"
                id="bathComplex"
                checked={orderServices.massage.location.bathComplex}
                onChange={(e) => {
                  setOrderServices({
                    ...orderServices,
                    massage: {
                      ...orderServices.massage,
                      location: { bathComplex: e.target.checked, room: false },
                    },
                  });
                }}
              />
              <label htmlFor="bathComplex">В банном комплексе</label>
            </div>
            <div className="checkbox">
              <input
                type="checkbox"
                name="room"
                id="room"
                checked={orderServices.massage.location.room}
                onChange={(e) => {
                  setOrderServices({
                    ...orderServices,
                    massage: {
                      ...orderServices.massage,
                      location: { bathComplex: false, room: e.target.checked },
                    },
                  });
                }}
              />
              <label htmlFor="room">В номере.</label>
            </div>
          </div>
          <div style={{ margin: "2rem 0 0" }}>Выберете вид массажа:</div>
          {Data.map(
            (el, key) =>
              el.active && <TypeOfMassage {...el} key={"massage" + key} />
          )}
        </>
      )}
    </>
  );
}
