import React, { useContext } from "react";
import { CostomContext } from "../../../_context.jsx";
import "./greeting.scss";
import { Link, useNavigate } from "react-router-dom";

export default function Greeting() {
  const {
    setAddFields,
    FIO,
    addFields,
    LISTTIME,
    PATCHNAME,
    form,
    house,
    dateResidence,
    start,
    setStart,
  } = useContext(CostomContext);

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    navigate("/" + PATCHNAME + "?next=4");
  };

  console.log(
    +addFields.arrivalTime.split(":")[0] >= 8 &&
      +addFields.arrivalTime.split(":")[0] <= 23
  );
  return (
    <form>
      <div
        className={
          form.end1
            ? "div--form end--active"
            : form.form1
            ? "div--form form--active"
            : "div--form"
        }
      >
        <span className="greeting--form">
          Доброго здравия, <span className="greeting--name">{FIO} </span>. Мы
          сами очень не любим заполнять всякие анкеты, а лицензионные соглашения
          к новому телефону читаем за 0,2 секунды. Но то, что представлено ниже
          — это 45% Вашего успешного и комфортного отдыха в Лукино. Потратьте
          несколько минут, чтобы мы в полной мере были готовы к встрече.
        </span>
        <div className="bathhouse--time">
          <span className="greeting--house">
            Для вас будут подготовлены Дом&nbsp;/&nbsp;Апартаменты:{" "}
            <span>{house}</span>
          </span>
          <span className="greeting--time">
            Утановленная дата проживания:
            <span>
              С {dateResidence.From} - По {dateResidence.To}{" "}
            </span>
          </span>
          <span className="greeting--form" style={{ marginTop: "1.2rem" }}>
            А теперь давайте приступим к заполнению анкеты!
          </span>
          <div className="form-arrival">
            <div>Примерное время прибытия:</div>
            <input
              className="input input_time"
              type="time"
              min="09:00"
              max="22:00"
              list="inputs_time4"
              name="arrival_time"
              autofocus
              required
              value={addFields.arrivalTime}
              onChange={(e) =>
                setAddFields({ ...addFields, arrivalTime: e.target.value })
              }
            />
            <datalist id="inputs_time4">{LISTTIME}</datalist>
          </div>
        </div>
        <div className="form-btn">
          {+addFields.arrivalTime.split(":")[0] >= 8 &&
          +addFields.arrivalTime.split(":")[0] <= 23 ? (
            <Link
              to={"/" + PATCHNAME + "?next=1"}
              onClick={() => setStart(true)}
              style={{ textDecoration: "none !important" }}
            >
              <button className="btn btn-primary" type="submit">
                <span>Продолжить</span>
              </button>
            </Link>
          ) : (
            <button className="btn btn-primary" type="submit">
              <span>Продолжить</span>
            </button>
          )}
        </div>
        {/* Дом&nbsp;/&nbsp;Апартаменты:
        <input
          className="input"
          type="text"
          name="home_user"
          id="home"
          value={house}
          disabled
          // onChange={(e) => setHouse(e.target.value)}
        />
      </div>
      <div className="div--form">
        Даты проживания:
        <div className="date__residence">
          <div className="date__residence--from">
            <span>С:</span>
            <input
              disabled
              value={dateResidence.From}
              className="input"
              type="date"
              name="dateFrom_user"
              id="dateFrom_user"
              // min={dates}
              // onChange={(e) =>
              //   setDateResidence({ ...dateResidence, From: e.target.value })
              // }
            />
          </div>
          <div className="date__residence--to">
            <span>По:</span>
            <input
              disabled
              value={dateResidence.To}
              className="input"
              type="date"
              name="dateTo_user"
              id="dateTo_user"
              // min={dateResidence.From ? dateResidence.From : dates}
              // onChange={(e) =>
              //   setDateResidence({ ...dateResidence, To: e.target.value })
              // }
            />
          </div>
        </div>
      </div>
      <div className="div--form">
        Фамилия Имя Отчество
        <input
          value={FIO}
          className="input"
          type="text"
          name="FIO_user"
          id="FIO_user"
          disabled
          // onChange={(e) => setFIO(e.target.value)}
        />
    */}
      </div>
    </form>
  );
}
