import React, { useContext } from "react";
import { CostomContext } from "../../../../_context.jsx";

export default function BabyBed() {
  const { crib, setCrib } = useContext(CostomContext);
  return (
    <div
      className={
        crib.active
          ? "bathhouse opencheckbox--show barbecue"
          : "bathhouse barbecue close--bathhouse"
      }
    >
      <div className="checkbox">
        <input
          type="checkbox"
          name="crib"
          id="crib"
          checked={crib.active}
          onChange={(e) => {
            setCrib({
              ...crib,
              active: e.target.checked,
            });
          }}
        />
        <label htmlFor="crib">Установить детскую кроватку</label>
        {crib.active && (
          <div className="form--active greeting--form bed">
            По Вашему желанию мы можем установить детскую кроватку с комплектом
            детского белья в любой комнате арендованного дома/апартамента.
          </div>
        )}
      </div>
      {crib.active && (
        <div className="checkbox--time extraSpace">
          <div>Количество детей:</div>
          <div className="bathhouse--time">
            <input
              className="input input_time extraSpace_range"
              type="number"
              min="1"
              max="7"
              id="crib_count"
              name="crib_count"
              value={crib.count}
              onChange={(e) => {
                setCrib({
                  ...crib,
                  count: e.target.value,
                });
              }}
            />
            <div
              className="item__add"
              onClick={() => {
                if (crib.count < 7)
                  setCrib({
                    ...crib,
                    count: crib.count + 1,
                  });
              }}
            >
              ‹
            </div>
            <div
              className="item__remove"
              onClick={() => {
                if (crib.count > 1)
                  setCrib({
                    ...crib,
                    count: crib.count - 1,
                  });
              }}
            >
              ‹
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
