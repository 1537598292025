import React, { useContext } from "react";
import { CostomContext } from "../../../../_context.jsx";

export default function CheckInOut() {
  const { check, setCheck, LISTTIME } = useContext(CostomContext);
  return (
    <>
      <div
        className={
          check.checkIn.active
            ? "bathhouse opencheckbox--show"
            : "bathhouse close--bathhouse"
        }
      >
        <div className="checkbox">
          <input
            type="checkbox"
            name="meal"
            id="checkIn"
            checked={check.checkIn.active}
            onChange={(e) => {
              setCheck({
                ...check,
                checkIn: { ...check.checkIn, active: e.target.checked },
              });
              console.log(check);
            }}
          />
          <label htmlFor="checkIn">Ранний заезд</label>
        </div>
        {check.checkIn.active && (
          <div className="checkbox--time">
            <div>Время:</div>
            <div className="bathhouse--time">
              <input
                className="input input_time"
                type="time"
                list="inputs_time5"
                min="09:00"
                max="22:00"
                name="checkIn_time"
                value={check.checkIn.time}
                onChange={(e) =>
                  setCheck({
                    ...check,
                    checkIn: { ...check.checkIn, time: e.target.value },
                  })
                }
              />
              <datalist id="inputs_time5">{LISTTIME}</datalist>
            </div>
          </div>
        )}
      </div>
      <div
        className={
          check.checkOut.active
            ? "bathhouse opencheckbox--show"
            : "bathhouse close--bathhouse"
        }
      >
        <div className="checkbox">
          <input
            type="checkbox"
            name="checkOut"
            id="checkOut"
            checked={check.checkOut.active}
            onChange={(e) => {
              setCheck({
                ...check,
                checkOut: { ...check.checkOut, active: e.target.checked },
              });
              console.log(check);
            }}
          />
          <label htmlFor="checkOut">Поздний выезд</label>
        </div>
        {check.checkOut.active && (
          <div className="checkbox--time">
            <div>Время:</div>
            <div className="bathhouse--time">
              <input
                className="input input_time"
                type="time"
                list="inputs_time5"
                min="09:00"
                max="22:00"
                name="checkOut_time"
                value={check.checkOut.time}
                onChange={(e) =>
                  setCheck({
                    ...check,
                    checkOut: { ...check.checkOut, time: e.target.value },
                  })
                }
              />
              <datalist id="inputs_time5">{LISTTIME}</datalist>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
