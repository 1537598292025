import React, { useContext } from "react";
import Midle from "./midle/formAdd.jsx";
import Header from "./header/header.jsx";
import "./_inputs.scss";
import "./_buttons.scss";
import "./_breadcrambs.scss";
import { Context } from "../_context.jsx";
import {BrowserRouter} from 'react-router-dom'

export default function Content() {
  return (
    <Context>
        <Header />
        <Midle />
    </Context>
  );
}
