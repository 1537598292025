import React, { useContext } from "react";
import { CostomContext } from "../../../../_context.jsx";
import "./banquet.scss";

export default function Banquet() {
  const { setAddFields, addFields, dateResidence } = useContext(CostomContext);
  const AddCount = (elements) => {
    setAddFields({
      ...addFields,
      banquet: {
        ...addFields.banquet,
        count: elements,
      },
    });
  };
  return (
    <div
      className={
        addFields.banquet.active
          ? "bathhouse banquet opencheckbox--show barbecue"
          : "bathhouse banquet barbecue close--bathhouse"
      }
    >
      <div className="checkbox">
        <input
          type="checkbox"
          name="banquet"
          id="banquet"
          checked={addFields.banquet.active}
          onChange={(e) => {
            setAddFields({
              ...addFields,
              banquet: { ...addFields.banquet, active: e.target.checked },
            });
          }}
        />
        <label htmlFor="banquet">Заказать банкет</label>
      </div>
      {addFields.banquet.active && (
        <div className="barbecue">
          <div className="form--active greeting--form bed">
            Вполне вероятно, что Вам не достаточно тех блюд, которые указаны в
            нашем меню, и Вам нужно что-то более изысканное и торжественное.
            Поделитесь своими пожеланиями, и мы обязательно предложим что-нибудь
            стоящее Вашего внимания.
          </div>
          <div
            className={
              addFields.banquet.active
                ? "bathhouse banquet--grid opencheckbox--show"
                : "bathhouse banquet--grid close--bathhouse"
            }
          >
            <div className="checkbox--time">
              <div>Дата банкета:</div>
              <div className="bathhouse--time">
                <input
                  value={addFields.banquet.date}
                  className="input banquet--input"
                  type="date"
                  name="banquetDate"
                  id="banquetDate"
                  min={dateResidence.From}
                  max={dateResidence.To}
                  required
                  // min={dates}
                  onChange={(e) =>
                    setAddFields({
                      ...addFields,
                      banquet: {
                        ...addFields.banquet,
                        date: e.target.value,
                      },
                    })
                  }
                />
              </div>
            </div>
            <div className="checkbox--time banquet--person">
              <div>Количество персон:</div>
              <div className="bathhouse--time">
                <input
                  className="input input_time extraSpace_range"
                  type="number"
                  min="1"
                  max="12"
                  id="banquetPerson"
                  name="banquetPerson"
                  value={addFields.banquet.count}
                  onChange={(e) => {
                    AddCount(e.target.value);
                  }}
                />
                <div
                  className="item__add"
                  onClick={() => {
                    if (addFields.banquet.count < 12)
                      AddCount(addFields.banquet.count + 1);
                  }}
                >
                  ‹
                </div>
                <div
                  className="item__remove"
                  onClick={() => {
                    if (addFields.banquet.count > 1)
                      AddCount(addFields.banquet.count - 1);
                  }}
                >
                  ‹
                </div>
              </div>
            </div>
          </div>
          <div className="bathhouse--time">
            <div style={{ marginBottom: "0.5rem" }}>
              Укажите пожалуйста ваши пожелания по банкету
            </div>
            <textarea
              className="textarea input_time banquet--textarea"
              placeholder="Пожалуйста, укажите все ваши требования к организации банкета"
              name="banquet_text"
              value={addFields.banquet.text}
              onChange={(e) => {
                setAddFields({
                  ...addFields,
                  banquet: { ...addFields.banquet, text: e.target.value },
                });
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
