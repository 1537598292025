import React, { useContext } from "react";
import { CostomContext } from "../../../../_context.jsx";

export default function Meal() {
  const { setAddFields, addFields, LISTTIME } = useContext(CostomContext);
  return (
    <>
      <div
        className={
          addFields.meal.active
            ? "bathhouse opencheckbox--show"
            : "bathhouse close--bathhouse"
        }
      >
        <div className="checkbox">
          <input
            type="checkbox"
            name="meal"
            id="meal"
            checked={addFields.meal.active}
            onChange={(e) => {
              setAddFields({
                ...addFields,
                meal: { ...addFields.meal, active: e.target.checked },
              });
              console.log(addFields);
            }}
          />
          <label htmlFor="meal">Заказать Обед / Ужин</label>
        </div>
        {addFields.meal.active && (
          <div className="checkbox--time">
            <div>Время:</div>
            <div className="bathhouse--time">
              <input
                className="input input_time"
                type="time"
                list="inputs_time5"
                min="09:00"
                max="22:00"
                name="meal_time"
                value={addFields.meal.time}
                onChange={(e) =>
                  setAddFields({
                    ...addFields,
                    meal: { ...addFields.meal, time: e.target.value },
                  })
                }
              />
              <datalist id="inputs_time5">{LISTTIME}</datalist>
            </div>
          </div>
        )}
      </div>
      {addFields.meal.active && (
        <div
          className={
            addFields.meal.active
              ? "bathhouse opencheckbox--show barbecue"
              : "bathhouse barbecue close--bathhouse"
          }
        >
          <div className="checkbox--time barbecue">
            <div className="bathhouse--time">
              <textarea
                placeholder="Пожалуйста, укажите количество порций, примерный вес и при необходимости наличие гарнира и напитков"
                className="textarea input_time banquet--textarea"
                name="meal_text"
                value={addFields.meal.text}
                onChange={(e) => {
                  setAddFields({
                    ...addFields,
                    meal: { ...addFields.meal, text: e.target.value },
                  });
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
