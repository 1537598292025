import React, { useContext } from "react";
import { CostomContext } from "../../../../_context.jsx";
import "./broom.scss";
import BroomOrdinary from "./broomOrdinary/broomOrdinary.jsx";
import BroomAromatic from "./broomAromatic/broomAromatic.jsx";

export default function Broom() {
  const { bathhouse, setBathhouse } = useContext(CostomContext);
  return (
    <div
      className={
        bathhouse.broom.active
          ? "broom opencheckbox--show"
          : "broom close--bathhouse"
      }
    >
      <div>
        <div className="checkbox">
          <input
            type="checkbox"
            name="broom"
            id="broom"
            checked={bathhouse.broom.active}
            onChange={(e) => {
              setBathhouse({
                ...bathhouse,
                broom: { ...bathhouse.broom, active: e.target.checked },
              });
            }}
          />
          <label htmlFor="broom">Веники</label>
        </div>
      </div>
      <div>
        {bathhouse.broom.active && (
          <>
            <div>
              <div style={{ margin: "0.5rem 0" }}>
                Дубовые и березовые.
              </div>
              <div
                className={
                  bathhouse.broom.broomOrdinary.active
                    ? "checkbox broom--ordinary opencheckbox--show"
                    : "checkbox broom--ordinary close-broom"
                }
              >
                <input
                  type="checkbox"
                  name="broomOrdinary"
                  id="broomOrdinary"
                  checked={bathhouse.broom.broomOrdinary.active}
                  onChange={(e) => {
                    setBathhouse({
                      ...bathhouse,
                      broom: {
                        ...bathhouse.broom,
                        broomOrdinary: {
                          ...bathhouse.broom.broomOrdinary,
                          active: e.target.checked,
                        },
                      },
                    });
                  }}
                />
                <label htmlFor="broomOrdinary">
                  Березовые&nbsp;/&nbsp;Дубовые
                </label>
              </div>
              {bathhouse.broom.broomOrdinary.active && <BroomOrdinary />}
              <div style={{ margin: "0.5rem 0" }}>Ароматические.</div>
              <div
                className={
                  bathhouse.broom.broomAromatic.active
                    ? "checkbox broom--aromatic opencheckbox--show"
                    : "checkbox broom--aromatic close-broom"
                }
              >
                <input
                  type="checkbox"
                  name="broomAromatic"
                  id="broomAromatic"
                  checked={bathhouse.broom.broomAromatic.active}
                  onChange={(e) => {
                    setBathhouse({
                      ...bathhouse,
                      broom: {
                        ...bathhouse.broom,
                        broomAromatic: {
                          ...bathhouse.broom.broomAromatic,
                          active: e.target.checked,
                        },
                      },
                    });
                  }}
                />
                <label htmlFor="broomAromatic">Ароматические</label>
              </div>
              {bathhouse.broom.broomAromatic.active && <BroomAromatic />}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
