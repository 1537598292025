import React, { useContext } from "react";
import { CostomContext } from "../../../../_context.jsx";

export default function AdultBed() {
  const { addFields, setAddFields } = useContext(CostomContext);
  return (
    <div
      className={
        addFields.extraSpace.active
          ? "bathhouse opencheckbox--show barbecue"
          : "bathhouse barbecue close--bathhouse"
      }
    >
      <div className="checkbox">
        <input
          type="checkbox"
          name="extraSpace"
          id="extraSpace"
          checked={addFields.extraSpace.active}
          onChange={(e) => {
            setAddFields({
              ...addFields,
              extraSpace: {
                ...addFields.extraSpace,
                active: e.target.checked,
              },
            });
          }}
        />
        <label htmlFor="extraSpace">Дополнительное место для взрослого</label>
        {addFields.extraSpace.active && (
          <div className="form--active greeting--form bed">
            Сообщите нам, если Вам потребуется дополнительное место для
            взрослого, — мы установим кровать с комплектом постельного белья.
            Просим учитывать, что максимальное количество дополнительных мест
            для 2-х местного и 4-х местного номера — 1 кровать, для 6-местного —
            2 кровати.
          </div>
        )}
      </div>
      {addFields.extraSpace.active && (
        <div className="checkbox--time extraSpace">
          {/* <div>Состав банкета:</div> */}
          <div>Количество персон:</div>
          <div className="bathhouse--time">
            <input
              className="input input_time extraSpace_range"
              type="number"
              min="1"
              max="2"
              id="extraSpace"
              name="extraSpace"
              value={addFields.extraSpace.count}
              onChange={(e) => {
                setAddFields({
                  ...addFields,
                  extraSpace: {
                    ...addFields.extraSpace,
                    count: e.target.value,
                  },
                });
              }}
            />
            <div
              className="item__add"
              onClick={() => {
                if (addFields.extraSpace.count < 2)
                  setAddFields({
                    ...addFields,
                    extraSpace: {
                      ...addFields.extraSpace,
                      count: addFields.extraSpace.count + 1,
                    },
                  });
              }}
            >
              ‹
            </div>
            <div
              className="item__remove"
              onClick={() => {
                if (addFields.extraSpace.count > 1)
                  setAddFields({
                    ...addFields,
                    extraSpace: {
                      ...addFields.extraSpace,
                      count: addFields.extraSpace.count - 1,
                    },
                  });
              }}
            >
              ‹
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
