import React, { useContext } from "react";
import { CostomContext } from "../../_context.jsx";
import "./modal.scss";

export default function Modal() {
  const { setLoadModal } = useContext(CostomContext);

  return (
    <div>
      <div className="modal--inner--wrapper"></div>
      <div className="modal--wrapper">
        <div className="modal--inner">
          <div className="modal--header">
            Спасибо, ваши данные переданы модераторам для обработки, они
            обязательно их обработают и если появятся вопросы с вами сразу
            свяжутся!
          </div>
          <div className="modal--bottom">
            <a href="https://lukino.ru/">
              <button
                className="btn modal--buttom"
                onClick={() => setLoadModal(false)}
              >
                <span>Ок</span>
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
