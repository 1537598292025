import React, { useContext } from "react";
import { CostomContext } from "../../../_context.jsx";
import { Link, useNavigate } from "react-router-dom";
import "./accommodation.scss";
import BabyBed from "./baby bed/baby--bed.jsx";
import AdultBed from "./adult bed/adult--bed.jsx";
import Pet from "./pet/pet.jsx";
import Wishes from "./wishes/wishes.jsx";
import CheckInOut from "./check/check.jsx";

export default function Accommodation() {
  const { FIO, PATCHNAME, form, setForm } = useContext(CostomContext);
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    navigate("/" + PATCHNAME + "?next=2");
  };
  return (
    <form onSubmit={handleSubmit}>
      <div
        className={
          form.end2
            ? "div--form end--active"
            : form.form2
              ? "div--form form--active"
              : "div--form"
        }
      >
        <span className="greeting--form">
          Уважаемый(-ая) <span className="greeting--name">{FIO}</span>. Мы будем
          рады поселить Вас раньше 14:00 и проводить позже стандартного
          расчетного часа 12:00, если будет такая возможность. За раннее
          заселение ничего доплачивать не нужно. Стоимость позднего выезда:
        </span>
        <div className="greeting--form accommodation--form">
          &#8212; ½ стоимости номера в текущие сутки при выезде между 16:00 и
          20:00
        </div>{" "}
        <div className="greeting--form accommodation--form">
          &#8212; полная стоимость номера в текущие сутки при выезде после
          20:00.
        </div>
        <CheckInOut />
        <BabyBed />
        <AdultBed />
        <Pet />
        <Wishes />
        <div className="form-btn">
          <Link
            to={"/" + PATCHNAME}
            style={{ textDecoration: "none !important" }}
          >
            <button
              className="btn btn-secondary"
              type="button"
              onClick={() => setForm({ ...form, end1: true })}
            >
              <span>Назад</span>
            </button>
          </Link>
          <button className="btn btn-primary" type="submit">
            <span>Далее</span>
          </button>
        </div>
      </div>
    </form>
  );
}
