import React, { useContext } from "react";
import { CostomContext } from "../../../../../_context.jsx";

export default function BroomOrdinary() {
  const { bathhouse, setBathhouse } = useContext(CostomContext);

  return (
    <>
      <div className="checkbox--time extraSpace">
        <div>Дубовых веников:</div>
        <div className="bathhouse--time">
          <input
            className="input input_time extraSpace_range"
            type="number"
            min="0"
            max="10"
            id="broomOrdinary_oak"
            name="broomOrdinary_oak"
            value={bathhouse.broom.broomOrdinary.oak}
            onChange={(e) => {
              setBathhouse({
                ...bathhouse,
                broom: {
                  ...bathhouse.broom,
                  broomOrdinary: {
                    ...bathhouse.broom.broomOrdinary,
                    oak: e.target.value,
                  },
                },
              });
            }}
          />
          <div
            className="item__add"
            onClick={() => {
              if (bathhouse.broom.broomOrdinary.oak < 10)
                setBathhouse({
                  ...bathhouse,
                  broom: {
                    ...bathhouse.broom,
                    broomOrdinary: {
                      ...bathhouse.broom.broomOrdinary,
                      oak: bathhouse.broom.broomOrdinary.oak + 1,
                    },
                  },
                });
            }}
          >
            ‹
          </div>
          <div
            className="item__remove"
            onClick={() => {
              if (bathhouse.broom.broomOrdinary.oak > 0)
                setBathhouse({
                  ...bathhouse,
                  broom: {
                    ...bathhouse.broom,
                    broomOrdinary: {
                      ...bathhouse.broom.broomOrdinary,
                      oak: bathhouse.broom.broomOrdinary.oak - 1,
                    },
                  },
                });
            }}
          >
            ‹
          </div>
        </div>
      </div>
      <div className="checkbox--time extraSpace">
        <div>Березовых веников:</div>
        <div className="bathhouse--time">
          <input
            className="input input_time extraSpace_range"
            type="number"
            min="0"
            max="7"
            id="broomOrdinary_birch"
            name="broomOrdinary_birch"
            value={bathhouse.broom.broomOrdinary.birch}
            onChange={(e) => {
              setBathhouse({
                ...bathhouse,
                broom: {
                  ...bathhouse.broom,
                  broomOrdinary: {
                    ...bathhouse.broom.broomOrdinary,
                    birch: e.target.value,
                  },
                },
              });
            }}
          />
          <div
            className="item__add"
            onClick={() => {
              if (bathhouse.broom.broomOrdinary.birch < 10)
                setBathhouse({
                  ...bathhouse,
                  broom: {
                    ...bathhouse.broom,
                    broomOrdinary: {
                      ...bathhouse.broom.broomOrdinary,
                      birch: bathhouse.broom.broomOrdinary.birch + 1,
                    },
                  },
                });
            }}
          >
            ‹
          </div>
          <div
            className="item__remove"
            onClick={() => {
              if (bathhouse.broom.broomOrdinary.birch > 0)
                setBathhouse({
                  ...bathhouse,
                  broom: {
                    ...bathhouse.broom,
                    broomOrdinary: {
                      ...bathhouse.broom.broomOrdinary,
                      birch: bathhouse.broom.broomOrdinary.birch - 1,
                    },
                  },
                });
            }}
          >
            ‹
          </div>
        </div>
      </div>
    </>
  );
}
